import { ReactNode, useEffect } from "react";
import useCustomSnackbar from "../hooks/useCustomSnackbar";

const ErrorProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const customSnackbar = useCustomSnackbar();

  useEffect(() => {
    const timer = setInterval(() => {
      const error = localStorage.getItem("error");
      if (error) {
        customSnackbar.showMessage(error, "error");
        localStorage.setItem("error", "");
      }
    }, 1000);
    return () => clearInterval(timer);
  }, [customSnackbar]);

  return <>{children}</>;
};

export default ErrorProvider;
