import { AuthenticationResult } from "@azure/msal-browser";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useResponseAccessToken } from "../../hooks/useResponseAccessToken";
import { unassignGitHubProfile } from "../../services/backendService";
import useCustomSnackbar from "../../hooks/useCustomSnackbar";

const UnassignConfirm: React.FC = () => {
  const { getAccessToken } = useResponseAccessToken();
  const navigate = useNavigate();
  const customSnackbar = useCustomSnackbar();

  const unassignUserProfile = async () => {
    var token: AuthenticationResult | null = await getAccessToken();
    if (token?.accessToken) {
      await unassignGitHubProfile(token?.accessToken);
      customSnackbar.showMessage("User unassigned!", "success");
      navigate("/");
    }
  };

  return (
    <div className="Center">
      <h1>Forte GitHub Sync</h1>

      <div>
        Are you sure you want to remove your account from ForteDigital Github
        Organization?{" "}
      </div>

      <div>
        <Button
          onClick={() => navigate("/")}
          sx={{
            marginTop: "10px",
          }}
          variant="contained"
        >
          Cancel
        </Button>
        <Button
          sx={{
            marginTop: "10px",
            marginLeft: "10px",
            backgroundColor: "#ff0000",
            ":hover": {
              backgroundColor: "#be4d25",
            },
          }}
          variant="contained"
          type="submit"
          onClick={() => unassignUserProfile()}
        >
          Remove
        </Button>
      </div>
    </div>
  );
};

export default UnassignConfirm;
