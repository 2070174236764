import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useResponseAccessToken } from "../../hooks/useResponseAccessToken";
import { assignGitHubProfile } from "../../services/backendService";
import useCustomSnackbar from "../../hooks/useCustomSnackbar";

const AssignConfirm: React.FC = () => {
  const { getAccessToken } = useResponseAccessToken();
  const navigate = useNavigate();
  const [userName, setUserName] = useState("");
  const [userId, setUserId] = useState("");
  const customSnackbar = useCustomSnackbar();

  useEffect(() => {
    setUserName(localStorage.getItem("userName") ?? "");
    setUserId(localStorage.getItem("userId") ?? "");
  }, []);

  const assignUserProfile = async () => {
    const token = await getAccessToken();
    if (token?.accessToken) {
      await assignGitHubProfile(token?.accessToken, userName, userId);
      customSnackbar.showMessage("User invited!", "success");
      navigate("/invitation-info");
    }
  };

  return (
    <div className="Center">
      <h1>Forte GitHub Sync</h1>

      <div>
        Are you sure you want to assign account{" "}
        <strong className="UserName">
          {userName} ({userId})
        </strong>{" "}
        to ForteDigital Organization?
      </div>
      <div>
        <Button
          onClick={() => navigate("/")}
          sx={{
            backgroundColor: "#ff0000",
            ":hover": {
              backgroundColor: "#be4d25",
            },
            marginTop: "10px",
          }}
          variant="contained"
        >
          Cancel
        </Button>
        <Button
          sx={{ marginTop: "10px", marginLeft: "10px" }}
          variant="contained"
          type="submit"
          onClick={() => assignUserProfile()}
        >
          Assign
        </Button>
      </div>
    </div>
  );
};

export default AssignConfirm;
