import { AuthenticationResult } from "@azure/msal-browser";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useResponseAccessToken } from "../../hooks/useResponseAccessToken";
import { getGitHubProfile } from "../../services/backendService";
import { useNavigate } from "react-router-dom";

const GitHubCallcack: React.FC = () => {
  const { getAccessToken } = useResponseAccessToken();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    let ignore = false;

    const getData = async () => {
      var token: AuthenticationResult | null = await getAccessToken();
      if (token !== null && !ignore) {
        const code = searchParams.get("code") as string;
        const profile = await getGitHubProfile(token.accessToken, code);
        localStorage.setItem("userName", profile.login);
        localStorage.setItem("userId", profile.id.toString());
        navigate(`/assign`);
      }
    };
    getData();

    return () => {
      ignore = true;
    };
  }, [getAccessToken, navigate, searchParams]);

  return <>Loading... you will be redirected soon!</>;
};

export default GitHubCallcack;
