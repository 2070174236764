import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Link from "@mui/material/Link";

const InvitationInfo: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className="Center">
      <h1>Forte GitHub Sync</h1>
      <div>
        Please check your email (connected to GitHubAccount) to accept
        invitation to organization or go{" "}
        <Link
          sx={{ cursor: "pointer" }}
          onClick={() =>
            window.open(
              "https://github.com/orgs/fortedigital/invitation",
              "_blank"
            )
          }
        >
          here
        </Link>{" "}
        and accept invitation.
      </div>
      <Button
        sx={{ marginTop: "10px", marginLeft: "10px" }}
        variant="contained"
        type="submit"
        onClick={() => navigate(`/`)}
      >
        OK
      </Button>
    </div>
  );
};

export default InvitationInfo;
