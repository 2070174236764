import {
  Alert,
  AlertColor,
  Button,
  ButtonProps,
  Snackbar,
  SnackbarProps,
} from "@mui/material";

export const CustomSnackbar: React.ComponentType<{
  message?: string | undefined;
  action?: string | undefined;
  ButtonProps?: Partial<ButtonProps<"button", {}>> | undefined;
  SnackbarProps: Partial<SnackbarProps>;
  customParameters: { autoHideDuration?: number };
}> = ({ message, action, ButtonProps, SnackbarProps }) => {
  if (message) {
    const severity = message.substring(0, message.indexOf("|")) as AlertColor;
    message = message.substring(message.indexOf("|") + 1, message.length);

    return (
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        autoHideDuration={3000}
        {...SnackbarProps}
      >
        <Alert
          severity={severity}
          action={
            action != null && (
              <Button color="inherit" size="small" {...ButtonProps}>
                {action}
              </Button>
            )
          }
        >
          {message}
        </Alert>
      </Snackbar>
    );
  } else {
    return <></>;
  }
};
