import { AlertColor } from "@mui/material";
import { useSnackbar } from "material-ui-snackbar-provider";
import { useMemo } from "react";

export default function useCustomSnackbar() {
  const snackbar = useSnackbar();

  return useMemo(() => {
    const showMessage = (message: string, type: AlertColor) =>
      snackbar.showMessage(`${type.toString()}|${message}`);
    return {
      showMessage,
    };
  }, [snackbar]);
}
