import { AuthenticationResult } from "@azure/msal-browser";
import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import { useResponseAccessToken } from "../../hooks/useResponseAccessToken";
import { GitGubProfile } from "../../common/types";
import { getUserData } from "../../services/backendService";
import { useNavigate } from "react-router-dom";
import GitHubIcon from "@mui/icons-material/GitHub";
import LogoutIcon from "@mui/icons-material/Logout";

const Home: React.FC = () => {
  const { getAccessToken } = useResponseAccessToken();
  const [authResult, setAuthResult] = useState<AuthenticationResult | null>();
  const [gitHubAccount, setGitHubAccount] = useState<GitGubProfile | null>();
  const [responseReceived, setResponseReceived] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    let ignore = false;

    const getData = async () => {
      var token: AuthenticationResult | null = await getAccessToken();

      if (token !== null && !ignore) {
        setAuthResult(token);
        const userData = await getUserData(token.accessToken);
        setGitHubAccount(userData);
        setResponseReceived(true);
      }
    };

    getData();
    return () => {
      ignore = true;
    };
    // eslint-disable-next-line
  }, []);

  const mapAccount = () => {
    window.location.href = process.env.REACT_APP_GITHUB_AUTH_URL as string;
  };

  const unassignAccount = async () => {
    navigate("/unassign");
  };

  return (
    <div className="Center">
      <h1>Forte GitHub Sync</h1>
      {!responseReceived && <div>Loading...</div>}
      {responseReceived && (
        <div>
          <div className="HomeAccount">
            <div className="HomeAccountItem">Your Microsoft account: </div>
            <div className="HomeAccountItem">
              <strong>{authResult?.account?.username}</strong>
            </div>
          </div>
          <br />
          <div>
            {gitHubAccount ? (
              <div className="HomeAccount">
                <div className="HomeAccountItem">Mapped GitHub account: </div>
                <div className="HomeAccountItem">
                  <strong className="UserName">{`${gitHubAccount.login} (${gitHubAccount.id})`}</strong>
                </div>
                <div className="HomeAccountItem">
                  {" "}
                  <Button
                    onClick={unassignAccount}
                    variant="outlined"
                    startIcon={<LogoutIcon />}
                  >
                    Unassign Account
                  </Button>
                </div>
              </div>
            ) : (
              <div className="HomeAccount">
                <div className="HomeAccountItem">Mapped GitHub account: </div>
                <Button
                  onClick={mapAccount}
                  sx={{ marginLeft: 5 }}
                  variant="outlined"
                  startIcon={<GitHubIcon />}
                >
                  Map Account
                </Button>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Home;
