import { useMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import { useCallback } from "react";
import { loginRequest } from "../authConfig";

export function useResponseAccessToken() {
  const { instance, accounts, inProgress } = useMsal();
  const getAccessToken = useCallback(async () => {
    await instance.handleRedirectPromise();

    const functionsRequest = {
      ...loginRequest,
      account: accounts[0],
    };

    try {
      const response = await instance.acquireTokenSilent(functionsRequest);
      return response;
    } catch (error) {
      await instance.acquireTokenRedirect(functionsRequest);
      return null;
    }
  }, [instance, accounts]);

  return {
    getAccessToken,
    canAccuireToken: inProgress === InteractionStatus.None,
  };
}
