import { InteractionType } from "@azure/msal-browser";
import { useMsalAuthentication } from "@azure/msal-react";

type Props = {
  children?: React.ReactNode;
};

export const RequireLogin: React.FC<Props> = ({ children }) => {
  useMsalAuthentication(InteractionType.Redirect);
  return <>{children}</>;
};
