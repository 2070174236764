import axios, { AxiosError } from "axios";
import { GitGubProfile } from "../common/types";

type RequestCallback<T> = () => Promise<T>;

const getHeaders = (token: string): any => {
  return {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
};

const executeRequest = async <T>(request: RequestCallback<T>): Promise<T> => {
  try {
    return await request();
  } catch (error: unknown) {
    const axiosError = error as AxiosError;

    if (axiosError.response?.data) {
      localStorage.setItem("error", axiosError.response?.data as string);
    }
    throw error;
  }
};

export const getGitHubProfile = async (
  token: string,
  code: string
): Promise<GitGubProfile> => {
  return executeRequest(async () => {
    const { data } = await axios.get<GitGubProfile>(
      `${process.env.REACT_APP_BACKEND_URL}/api/GetGitHubProfile?code=${code}`,
      getHeaders(token)
    );
    return data;
  });
};

export const assignGitHubProfile = async (
  token: string,
  login: string,
  id: string
): Promise<GitGubProfile> => {
  return executeRequest(async () => {
    const { data } = await axios.post<GitGubProfile>(
      `${process.env.REACT_APP_BACKEND_URL}/api/AssignGitHubProfile`,
      {
        login: login,
        id: id,
      },
      getHeaders(token)
    );
    return data;
  });
};

export const getUserData = async (
  token: string
): Promise<GitGubProfile | null> => {
  return executeRequest(async () => {
    const { data } = await axios.get<GitGubProfile | null>(
      `${process.env.REACT_APP_BACKEND_URL}/api/GetAssignedProfile`,
      getHeaders(token)
    );
    return data;
  });
};

export const unassignGitHubProfile = async (token: string): Promise<null> => {
  return executeRequest(async () => {
    const { data } = await axios.post<null>(
      `${process.env.REACT_APP_BACKEND_URL}/api/UnassignGitHubProfile`,
      {},
      getHeaders(token)
    );
    return data;
  });
};
