import "./App.css";
import React from "react";
import ModalProvider from "mui-modal-provider";
import Home from "./pages/home/Home";
import GitHubCallcack from "./pages/github-callback/GitHubCallcack";
import AssignConfirm from "./pages/assign-confirm/AssignConfirm";
import UnassignConfirm from "./pages/unassign-confirm/UnassignConfirm";
import ErrorProvider from "./common/ErrorProvider";
import { SnackbarProvider } from "material-ui-snackbar-provider";
import { CustomSnackbar } from "./common/CustomSnackbar";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { msalConfig } from "./authConfig";
import { MsalProvider } from "@azure/msal-react";
import { RequireLogin } from "./RequireLogin";
import { PublicClientApplication } from "@azure/msal-browser";
import { Outlet, Route, Routes } from "react-router";
import InvitationInfo from "./pages/invitation-info/InvitationInfo";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

function App() {
  const msalInstance = new PublicClientApplication(msalConfig);

  return (
    <React.StrictMode>
      <ThemeProvider theme={darkTheme}>
        <ModalProvider>
          <SnackbarProvider
            SnackbarProps={{ autoHideDuration: 4000 }}
            SnackbarComponent={CustomSnackbar}
          >
            <MsalProvider instance={msalInstance}>
              <RequireLogin>
                <ErrorProvider>
                  <Routes>
                    <Route
                      element={
                        <div className="App">
                          <Outlet />
                        </div>
                      }
                    >
                      <Route path="/" element={<Home />} />
                      <Route path="/callback" element={<GitHubCallcack />} />
                      <Route path="/assign" element={<AssignConfirm />} />
                      <Route path="/unassign" element={<UnassignConfirm />} />
                      <Route
                        path="/invitation-info"
                        element={<InvitationInfo />}
                      />
                    </Route>
                  </Routes>
                </ErrorProvider>
              </RequireLogin>
            </MsalProvider>
          </SnackbarProvider>
        </ModalProvider>
      </ThemeProvider>
    </React.StrictMode>
  );
}

export default App;
